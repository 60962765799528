@font-face {
  font-family: 'minecraft_fiftysolid';
  src: url('../fonts/minecraftfifty-solid-webfont.woff2') format('woff2'),
  url('../fonts/minecraftfifty-solid-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

h1 {
  font-family: 'minecraft_fiftysolid';
  font-size: 48px;
  line-height: 46px;
  align-items: center;
  text-align: center;
  letter-spacing: 2px;
  line-height: 60px;
  span {
    color: rgb(20, 241, 149);
  }
  @media only screen and (max-width: 500px) {
    font-size: 35px;
    line-height: 50px;
  }

}

h2 {
  color: #897DA9;
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  text-align: center;


  ol {
    margin: auto;
    margin-top:30px;
    max-width: 400px;
    font-size: 16px;
    line-height: 25px;
    li {
      text-align: left;
    }
  }

  @media only screen and (max-width: 500px) {
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 0px;

    ol {
      font-size: 12px;
    }
  }
}

#site-footer {
  margin-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 14px;
  padding-bottom: 15px;

  .w-info {
    padding-bottom: 15px;
    p {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  a {
    color: rgb(20, 241, 149);
    text-decoration: none;
    &:visited {
      color: rgb(20, 241, 149);
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.mainWrapper {
  margin: auto;
  max-width: 1340px;
  margin: auto;
  margin-top: 30px;
  padding-bottom: 40px;
  padding: 1px;
  border-radius: 11px;
  background: linear-gradient(245.22deg,#da2eef 7.97%,#2b6aff 25.17%,#14F195FF 50.1%, #da2eef 70%, #2b6aff 80%,#14F195FF 92.1%);
  background-size: 200% 200%;
  animation: gradient 5s ease infinite;

  @media only screen and (max-width: 1140px) {
    margin-top: 0px;
  }
}


.insideWrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: space-evenly;
  flex-direction: row;
  background: linear-gradient(180deg, #03030d 0%, #10081e 100%);
  border-radius: 10px;
  padding: 20px;
}

.flexItem {
  text-align: center;
  flex-grow: 1;
  margin: auto;

  &.right {
    width: 100%;
    max-width: 600px;
    flex-grow: 0;
    margin: auto;
  }
}

#container {
  margin-top: 10px;

  @media only screen and (max-width: 500px) {
    margin-left: -20px;
    width: calc(100% + 40px);
  }
}


.currencies, #otherCurrencies {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-width: 400px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 0px;
  flex-wrap: wrap;

  a {
    border: solid 2px transparent;
  }
  a:active {
    border: solid 2px #FFF;
    border-radius: 50%;
  }
  a:focus {
    border: solid 2px #FFF;
    border-radius: 50%;
  }

  .coin {
    border: solid 2px transparent;
    cursor: pointer;
    padding: 4px;
    width: 44px;
    height: 44px;
    flex-grow: 0;
    flex-shrink: 0;

    &.empty {
      width: 48px;
      height: 48px;
      cursor: auto;
      border: solid 4px transparent;
    }

    &:target {
      border: solid 2px #FFF;
      border-radius: 50%;
    }

    img {
      width: 32px;
      height: 32px;
    }

    &#more {
      width: 50px;
      height: 50px;
      padding: 0px;
      border: solid 4px transparent;

      .morebg {
        width: 34px;
        height: 34px;
        margin: 4px;
        background: #5a5a5a;
        border-radius: 50%;
        letter-spacing: -2px;
        font-weight: bold;
        line-height: 23px;
      }
    }
  }
}

.currencies {
  @media only screen and (max-width: 500px) {
    margin-top: 0px;
  }
}

#otherCurrencies {
  display: none;
  margin-top: 0px;
}

.coins {
  margin-top:30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  display: none;


  &.selected {
    display: flex;
  }

  .coin {
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
    img {
      max-width: 60px;
    }
  }

  @media only screen and (max-width: 500px) {
    margin-left: -10px;
    .coin {
      min-width: 30px;
      max-width: 60px;
      flex-shrink: 1;
    }
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right
}

.slider {
  margin: 0px;
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background: #262036;
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  z-index: 2;
  position: relative;
  display: block;
}

.rotationSlides {
  margin-top: 40px;
  margin-bottom: 70px;
}

.half-slider {
  width: 45%
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: rgb(20, 241, 149);
  cursor: pointer;
  border: solid 2px #FFF;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: rgb(20, 241, 149);
  cursor: pointer;
}
label {
  font-weight: bold;
  margin: 10px;
  display: block;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

}

.inputfile + label {
  width: 220px;
  padding-right: 10px;
  cursor: pointer;
  display: inline-block;
  color: #FFF;
  border-radius: 30px;
  margin-top: 30px;
  background: none;
  border: solid 1px #FFFFFF;
  height: 62px;

  div {
    width: 40px;
    height: 40px;
    display: inline-block;
    padding: 5px;
    margin: 10px;
  }

  &.active {
    background: rgb(20, 241, 149);
    border: solid 1px transparent;
    color: black;
    div {
      img {
        filter: invert(1);
      }
    }
  }


  @media only screen and (max-width: 540px) {
    width: 180px;
    font-size: 13px;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 20px;
    height: 52px;

    div {
      width: 30px;
      height: 30px;
    }
  }
}
.inputfile + label img {
  width: 100%;
  height: 100%;
  fill: #ffffff;
}


#save {
  width: 220px;
  padding-right: 10px;
  cursor: pointer;
  display: inline-block;
  color: #FFF;
  border-radius: 30px;
  border: solid 1px #FFFFFF;
  font-weight: bold;
  margin-top: 30px;
  background: none;
  height: 62px;

  div {
    width: 40px;
    height: 40px;
    display: inline-block;
    padding: 5px;
    margin: 10px;
  }

  &.active {
    background: rgb(20, 241, 149);
    border: solid 1px transparent;
    color: black;
    div {
      img {
        filter: invert(1);
      }
    }
  }
  @media only screen and (max-width: 540px) {
    margin-top: 5px;
    width: 180px;
    font-size: 13px;
    height: 52px;
    margin-left: 40px;
    margin-right: 40px;

    div {
      width: 30px;
      height: 30px;
    }
  }

}
#save img {
  width: 100%;
  height: 100%;
  fill: #ffffff;
}


.konvajs-content {
  margin: auto
}

.footer-logo {
  img {
    width: 150px;
  }
}

footer {


}

.telegramText {
  font-size: 11px;
}
